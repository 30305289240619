import { HttpClient } from './http-client';

export class Api {
    private origin = window.location.origin;
    private baseUrl;
    private http;

    constructor() {
        this.http = new HttpClient();
        if (this.origin.includes('qa') || this.origin.includes('localhost') || this.origin.includes('dev')) {
            this.baseUrl = 'https://ecommapi.qa.trurating.site/trusurvey/';
        } else { 
            this.baseUrl = 'https://ecommapi.trurating.com/trusurvey/';   
        }
    }

    public URL(url) {
        return this.baseUrl + url;
    }

    public addAuthHeaders() {
        const requestHeaders = new Headers();
        const searchParams = new URLSearchParams(window.location.search)
        const merchantUrl = searchParams.get('merchant') || '';
        requestHeaders.append('User-Agent', 'API-Test');
        requestHeaders.append('Accept', 'application/json');
        requestHeaders.append('Content-Type', 'application/json');
        requestHeaders.append('x-tr-ecomm-referer', merchantUrl);
        requestHeaders.append('Accept-Encoding', 'gzip, deflate, br');
        requestHeaders.append('Accept-Language', 'en-US,en;q=0.9');
        return requestHeaders;
    }

    public async get(url?: string) {
        const requestURL = this.URL(url);
        return this.http.get(requestURL, this.addAuthHeaders());
    }

    public async post(url?: string, body?: any ) {
        const requestURL = this.URL(url);
        return this.http.post(requestURL, this.addAuthHeaders(), body);
    }
}
