import * as React from 'react';
import { QuantitativeFeedbackComponent } from '../quantitative-feedback/quantitative-feedback.component';
import './user-feedback.component.scss';

class UserFeedbackComponent extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      enableSendButton: false,
      rating: null,
      feedbackMessage: '',
      canContact: false,
      showFeedbackForm: true
    };
  }

  public componentDidMount(){
    const searchParams = new URLSearchParams(window.location.search)
    const selectedRating = searchParams.get('selectedRating') || '';
    const questionTypeValue = this.props.questionResponse.multipleChoiceOptions ?  'Multiple Choice Question' : 'Scaled Question';
    this.setState({
      rating: selectedRating,
      enableSendButton: (parseInt(selectedRating) >= 0 && parseInt(selectedRating)<= 9) ? true : false
    })
    window['dataLayer'].push({
      questionType: questionTypeValue,
      merchantName: this.props.questionResponse.merchantName,
      event: 'questionTypeEvent'
    });
  }

  public render() {
    return (
        <div className='feedback-container'>
           { this.state.showFeedbackForm ? this.renderFeedbackForm() : this.renderContactform() }
           { this.renderSendButton()}
        </div>
    )
  }

  private renderFeedbackForm = () => {
    return (
     <>
      {
          this.props.questionResponse &&
          <div>
            <span className='title'>{ this.props.questionResponse.qText }</span>
            <QuantitativeFeedbackComponent startRating={0} endRating={9} questionResponse={ this.props.questionResponse } onRatingSelect={this.selectRating} selectedRating={this.state.rating}/>
            <div className='qualitative-feedback'>
                <div className={this.state.feedbackMessage ? 'textarea-container tru-textarea--has-value' : 'textarea-container'}>
                    <textarea
                      rows={7}
                      placeholder='You can change your rating by clicking above. &#10;If you have any additional feedback please leave it here...'
                      value={this.state.feedbackMessage}
                      onChange={this.feedbackMessageChangeHandler}
                      maxLength={250}
                    />
                  </div>
            </div>
            <div className='can-contact-checkbox' hidden>
                <input
                        checked={this.state.canContact}
                        name='can-contact-checkbox'
                        onChange={this.canContactClickedHandler}
                        type='checkbox'
                        id='can-contact-checkbox'
                        className='mdc-checkbox__native-control'
                />
                <span className='checkbox-text'>I would like to be contacted by JD Sports regarding my answers.</span>
            </div>
        </div>
      }
     </>
    );
  }

  private renderSendButton = () => {
      return(
          <div>
            <div className="submit-button-container" data-gtm="gtm_send_button">
                <button onClick={this.submitHandler} disabled={!this.state.enableSendButton}>Send</button>
            </div>
            <div className="privacy-policy-container">
                <span>One question, always anonymous.</span>
                <span data-gtm="gtm_privacy_policy_link">
                    <a href="https://trurating.com/privacy-cookie-policy/" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>
                </span>
            </div>
          </div>
      )
  }

  private renderContactform = () => {
      return (
          <div className="contact-form">
              <div className="to-be-contacted-text">
                  To be contacted by The Rake, please fill in your details.
              </div>
              <div className="form-text-input">
                  <label>Name</label> <br/>
                  <input type="text"/>
              </div>
              <div className="form-text-input">
                  <label>Phone number</label> <br/>
                  <input type="text"/>
              </div>
              <div className="form-text-input">
                  <label>Email address</label> <br/>
                  <input type="text"/>
              </div>
          </div>
      )
  }

  private selectRating = (selectedRating ) => {
    this.setState({
      rating: selectedRating,
      enableSendButton: selectedRating >= 0
    });
  }

  private feedbackMessageChangeHandler = (event ) => {
    const message = event.target.value;
    this.setState({
      feedbackMessage: message,
      enableSendButton: this.state.rating === null ? false : true
    });
  }

  private canContactClickedHandler = (event ) =>{
    this.setState({canContact: event.target.checked});
  }

  private submitHandler = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const initialRating = searchParams.get('selectedRating') || '';
    window['dataLayer'].push({
      ratingChange: initialRating === this.state.rating ? 'Rating same as email' : 'Rating changed',
      event: 'ratingChangeEvent'
    });
    this.props.handleSubmit(this.state.rating, this.state.feedbackMessage);
  }
}

export default UserFeedbackComponent;
