import * as React from 'react';
import UserFeedbackComponent from '../../components/user-feedback/user-feedback.component';
import FeedbackDetailsComponent from '../../components/feedback-details/feedback-details.component';
import { Api } from '../../services/api.service';

import './home.page.scss';
import ThankyouImg from '../../assets/thank-you.svg';
import SorryImg from '../../assets/sorry.svg';
import OopsImg from '../../assets/oops.svg';
import TruRatingLogo from '../../assets/trurating-logo.svg';
import InstagramImg from '../../assets/social-links/instagram.svg';
import FacebookImg from '../../assets/social-links/facebook.svg';
import LinkedinImg from '../../assets/social-links/linkedin.svg';
import TwitterImg from '../../assets/social-links/twitter.svg';
import BackgroundImg from '../../assets/wave-background.svg';
import OvalImg from '../../assets/oval.svg';

class HomePage extends React.Component<any, any> {
    private api;

    constructor(props: any){
        super(props);
        this.api = new Api();
        this.state = {
            loading: true,
            showFeedbackComponent : true,
            showSuccessScreen:  false,
            showExpiredScreen: false,
            hasRated: false,
            qid: '',
            correlationId:'',
            questionResponse: null
        }
    }

    public componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search)
        const correlationId = searchParams.get('correlationId') || '';
        this.setState({
          correlationId: correlationId,
        }, () => this.getQuestions())
    }

    private getQuestions = () => {
        const currentDate = new Date();
        const questionsEndpoint = 'QuestionRecall/'+ this.state.correlationId;
        return this.api.get(questionsEndpoint)
            .then((data) => {
                this.setState({
                    loading: false,
                    questionResponse : data.questionResponse,
                    qid: data.questionResponse.qid,
                    showExpiredScreen: currentDate.getTime() > new Date(data.expiresAt).getTime() ? true : false,
                    hasRated: data.hasRated ? true : false
                })
            }).catch((error) => {
                throw error;
            });
    }

    public render() {
        return (
            <div className={ this.state.showFeedbackComponent ? 'home-page-wrapper':'home-page-wrapper height-override'}>
                <img src={BackgroundImg} alt="Img"  className="background-img"/>
                        <div className="home-page-layout">
                            <header><img src={TruRatingLogo} alt="TruRatingLogo" /></header>
                            {
                                this.state.loading ?
                                    <div className="loading-wrapper"><img src={OvalImg} alt="LoadingImg"/></div> :
                                    <section>
                                    {
                                        this.state.hasRated ||  this.state.showExpiredScreen ?
                                            this.renderExpiredScreen() :
                                            this.state.showFeedbackComponent ?
                                                <>
                                                    <UserFeedbackComponent  questionResponse={ this.state.questionResponse } handleSubmit={this.handleSubmit}/>
                                                    <FeedbackDetailsComponent />
                                                </> :
                                            (this.state.showSuccessScreen ? this.renderSuccessScreen() :  this.renderErrorScreen())
                                    }
                                    </section>
                            }
                        </div>
                { this.renderFooter()}
            </div>
         );
    }

    private renderFooter = () => {
        return (
            <footer>
                <div className="copy-right">© 2020 TruRating Ltd - TruRating is a registered trademark of TruRating Ltd and has patents pending</div>
                <div className="social-links">
                    <a href="https://www.instagram.com/trurating/" target="_blank" rel="noopener noreferrer"><img src={InstagramImg} alt="instagram" /></a>
                    <a href="https://www.facebook.com/trurating" target="_blank" rel="noopener noreferrer"><img src={FacebookImg} alt="facebook" /></a>
                    <a href="https://www.linkedin.com/company/trurating" target="_blank" rel="noopener noreferrer"><img src={LinkedinImg} alt="linkedin" /></a>
                    <a href="https://twitter.com/trurating" target="_blank" rel="noopener noreferrer"><img src={TwitterImg} alt="twitter" /></a>
                </div>
            </footer>
        )
    }

    private handleSubmit = (rating, feedbackMessage) => {
        const ratingsEndpoint = 'Ratings';
        const requestRatingBody = {
            "correlationId" : this.state.correlationId,
            "qid": this.state.qid,
            "isLive": true,
            "rating": rating,
            "comment": feedbackMessage || '',
            "languageCode":"en"
        };
        return this.api.post(ratingsEndpoint, requestRatingBody)
            .then(() => {
                this.setState({
                    showFeedbackComponent: false,
                    showSuccessScreen : true
                });
            }).catch(() => {
                this.setState({
                    showFeedbackComponent: false,
                    showSuccessScreen : false
                });
            });
    }

    private renderSuccessScreen = () => {
        return (
            <div className='success-container'>
                <div className="thankyou-text">Thank you. Your feedback is on its way.</div>
                <img src={ThankyouImg} alt="thankyou" />
                <div className="visit-text">To learn more about the charities your rating is helping, visit:</div>
                <div className="charity-link" data-gtm="gtm_charity_link"><a href="https://www.trurating.com/charity" target="_blank" rel="noopener noreferrer">www.trurating.com/charity</a></div>
                <div className="privacy-policy-container">
                    <span>One question, always anonymous.</span>
                    <span data-gtm="gtm_privacy_policy_link">
                        <a href="https://trurating.com/privacy-cookie-policy/" target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                        </a>
                    </span>
                </div>
            </div>
        )
    }

    private renderErrorScreen = () => {
        return (
            <div className='response-container'>
                <div>
                    <h3>Sorry, something went wrong. </h3>
                    <div>We are unable to capture your feedback right now.</div>
                    <div>Please try again later. Thank you.</div>
                </div>
                <div>
                    <img src={SorryImg} alt="sorry" />
                </div>
            </div>
        )
    }

    private renderExpiredScreen = (  ) => {
        const sorryMsg = (this.state.hasRated) ? 'Sorry, your link has already been used to submit feedback.'
                            : 'Sorry, your feedback link is now expired.';
        const feedbackMsg = (this.state.hasRated) ? 'We would love to collect your feedback, but it looks like your link has already been used.'
                            : 'We would love to collect your feedback, but unfortunately more than 30 days have passed since emailing you.';
        return (
            <div className='response-container'>
                <div>
                    <h4>{ sorryMsg }</h4>
                    <div>{ feedbackMsg }</div>
                </div>
                <div>
                    <img src={OopsImg} alt="oops" />
                </div>
            </div>
        )
    }
}

export default HomePage;