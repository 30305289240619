import React from 'react';
import './quantitative-feedback.component.scss';

interface IQuantitativeFeedback {
  startRating: number;
  endRating: number;
  onRatingSelect: any;
  selectedRating: string;
  questionResponse : any;
}

export class QuantitativeFeedbackComponent extends React.Component<IQuantitativeFeedback, any> {

  public render() {
    return (
      <div className='quantitative-feedback'>
        {
          this.props.questionResponse ? 
            ( this.props.questionResponse.multipleChoiceOptions
              ? this.renderBinaryButtons()
              : this.renderScaledButtons() )
            : null 
        } 
      </div>
    );
  }

  private renderBinaryButtons = () => {
    return <>
              <div className='rating-buttons multiple-options'>
                  {
                    this.props.questionResponse.multipleChoiceOptions.map((item, index) => {
                      const currentRating = parseInt(this.props.selectedRating);
                      const ratingButtonClasses  = parseInt(item.value) === currentRating ? 'rating-button selected' : 'rating-button';
                      return <div className="binary-options" key={index}>
                                <button className={ ratingButtonClasses }  onClick={this.ratingSelectionHandler} value={item.value}></button>
                                <div className="binary-label">{item.label}</div>
                             </div>
                    })
                  }
              </div>
            </>
  }

  private renderScaledButtons = () => {
    return <>
              <div className='rating-buttons'>
                  {this.getRatingButtons(this.props.startRating, this.props.endRating)}
              </div>
              <div className='rating-values'>
                  {
                    Object.values(this.props.questionResponse.scaleDescriptors).map((label : any, index) => {
                      return <span key={index}>{label}</span>;
                    })
                  }
              </div>
            </>
  }

  private getRatingButtons(startRating, endRating) {
    const ratingButtons: any = [];
    for (let i = startRating; i <= endRating; i++) {
      const currentRating = parseInt(this.props.selectedRating);
      const ratingButtonClasses = (currentRating !== null && currentRating === i) ? 'rating-button selected' : 'rating-button';
      ratingButtons.push(<button className={ratingButtonClasses} key={i} onClick={this.ratingSelectionHandler} value={i}>{i}</button>);
    }
    return ratingButtons;
  }

  private ratingSelectionHandler = (event) => {
    const rating = parseInt(event.target.value);
    this.setState({
      currentRating: rating
    });
    this.props.onRatingSelect(rating);
  }
}
