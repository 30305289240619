import * as React from 'react';
import './feedback-details.component.scss';

const FeedbackDetailsComponent = () => {
    return ( 
        <div className="feedback-details-container">
            <div>
                <div className="question">What is TruRating?</div>
                <div>We collect feedback from real customers like you.</div>
                <div className="first-ans">Our ratings are from paying customers that visited the store or purchased online and all feedback is anonymous.</div>
            </div>
            <div>
                <div className="question">Why you should rate?</div>
                <div> Not only does every rating we collect help improve your shopping experiences, but it helps to improve the lives of children around the world. 
                    When you rate, we donate to one of 3 amazing kids’ charities.
                </div>
            </div>
            { getCharity() }
        </div>
    );
}

const getCharity = () => {
    const charityMessages = [
      {
        image: 'charity-dental-kit.svg',
        text: 'Your rating helped KidsCan provide a dental kit for a child in need'
      },
      {
        image: 'charity-palate-surgery.svg',
        text: "Your rating funded a child's cleft palate surgery with SmileTrain"
      },
      {
        image: 'charity-raincoat.svg',
        text: 'Your rating helped KidsCan provide a raincoat for a child in need'
      },
      {
        image: 'charity-smile.svg',
        text: 'Your rating gave a child a chance to smile with SmileTrain'
      },
      {
        image: 'charity-housing.svg',
        text: 'Your rating helped Families First provide housing for a foster child'
      },
      {
        image: 'charity-fostercare.svg',
        text: 'Your rating helped Families First support youth in foster care'
      },
      {
        image: 'charity-donations.svg',
        text: 'Your rating supports helpful donations through TruCharity'
      },
      {
        image: 'charity-causes.svg',
        text: 'Your rating supports great causes with TruCharity'
      },
      {
        image: 'covid-19.svg',
        text: 'We are supporting foodbanks to help with coronavirus relief'
      }
    ];
    const selectedCharityMessage = charityMessages[Math.floor(Math.random() * charityMessages.length)];
    const imagePath = require(`../../assets/charity/${selectedCharityMessage.image}`);

    return (
        <div className="charity">
            <img src={imagePath} alt="Charity" />
            <div>{selectedCharityMessage.text}</div>
        </div>
     );
}
 
export default FeedbackDetailsComponent;