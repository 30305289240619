export class HttpClient {

    public requestOptions(url, options?) {
        return new Request(url, options);
    }

    public status(response) {
        if (response.ok) {
            return response.json();
        } else {
           const error = JSON.stringify({
                status: response.status,
                message: response.statusText
            });
           throw new Error(error);
        }
    }

    public handleError(error) {
        throw error;
    }

    public get(url?: string, headers?: any) {
        const requestOptions = this.requestOptions(url, {method: 'GET',  headers: headers});
        return fetch(requestOptions)
                .then(this.status)
                .catch(this.handleError);
    }

    public post(url?: string, headers?: any, body?: any) {
        const request = this.requestOptions(url, {body : JSON.stringify(body), method: 'POST',  headers: headers});
        return fetch(request)
                .then(this.status)
                .catch(this.handleError);
    }
}
